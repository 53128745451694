import { h, Component } from 'preact'  // eslint-disable-line

export default function IconTwitter (props) {
  const color = props.color || '#fff'
  const svgclass = props.className || ''
  return (
    <svg xmlns='http://www.w3.org/2000/svg'
      className={svgclass}
      viewBox='123.445 0 600 600'>
      <circle fill={color} cx='420.944' cy='296.781' r='294.5' />
      <path fill='#1f1f1f' d='M609.773 179.634c-13.891 6.164-28.811 10.331-44.498 12.204 16.01-9.587 28.275-24.779 34.066-42.86a154.78 154.78 0 0 1-49.209 18.801c-14.125-15.056-34.266-24.456-56.551-24.456-42.773 0-77.461 34.675-77.461 77.473 0 6.064.682 11.98 1.996 17.66-64.389-3.236-121.474-34.079-159.684-80.945-6.672 11.446-10.491 24.754-10.491 38.953 0 26.875 13.679 50.587 34.464 64.477a77.122 77.122 0 0 1-35.097-9.686v.979c0 37.54 26.701 68.842 62.145 75.961-6.511 1.785-13.344 2.716-20.413 2.716-4.998 0-9.847-.472-14.584-1.364 9.859 30.769 38.47 53.166 72.363 53.799-26.515 20.785-59.925 33.174-96.212 33.174-6.25 0-12.427-.372-18.491-1.104 34.291 21.988 75.006 34.824 118.759 34.824 142.496 0 220.427-118.052 220.427-220.428 0-3.361-.074-6.697-.236-10.021a157.835 157.835 0 0 0 38.707-40.157z' />
    </svg>
  )
}
