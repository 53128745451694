import { h, Component } from 'preact'  // eslint-disable-line

export default function IconTwitter (props) {
  const color = props.color || '#fff'
  const svgclass = props.className || ''
  return (
    <svg xmlns='http://www.w3.org/2000/svg'
      className={svgclass}
      viewBox='0 0 415 415'>
      <path fill={color} d='M204.11 408.221c112.729 0 204.11-91.385 204.11-204.11C408.221 91.388 316.839 0 204.11 0 91.388 0 0 91.388 0 204.111c0 112.725 91.388 204.11 204.11 204.11zm-25.483-193.699c11.401-2.425 22.598-1.872 32.496 1.025v-98.959c0-9.676 7.847-17.527 17.528-17.527 9.69 0 17.541 7.851 17.541 17.527v10.487a95.451 95.451 0 0 0 11.806 5.013c17.128 5.953 28.649 19.917 29.691 34.59.32 4.412.444 8.233.469 11.423.044 6.31-1.495 12.405-2.785 13.619-1.286 1.216-3.939 1.216-5.931 0-1.996-1.214-3.527-6.382-5.566-10.898-1.118-2.457-2.869-4.967-5.619-6.803-3.767-2.515-13.196-4.923-22.064-8.253v89.186c0 1.029-.133 2.023-.301 3.037-.396 22.586-19.885 43.745-47.504 49.616-31.23 6.641-60.981-8.832-66.437-34.528-5.454-25.714 15.44-51.923 46.676-58.555z' />
    </svg>
  )
}
